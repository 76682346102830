@use 'styles/base/mixins';

.sponsoredHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 var(--spacing-04) var(--spacing-08);
  border-bottom: 2px solid var(--gray-bright);

  p {
    margin-bottom: 0;
  }

  @include mixins.devices(desktopXl) {
    margin: 0 0 var(--spacing-08);

    a {
      font-size: 24px;
    }
  }

  .imageWrapper {
    text-align: right;

    p {
      margin-bottom: 0;
      text-align: right;
    }

    img {
      max-height: 45px;
      font-size: 10px;
    }

    &:only-child {
      margin-left: auto;
    }

    @include mixins.devices(desktopXl) {
      margin-right: var(--spacing-05);

      a {
        font-size: 24px;
      }
    }
  }
}
