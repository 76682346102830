@use 'styles/base/mixins';

.root {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--spacing-04);
}

.prevWrapper {
  flex-basis: 50%;
}

.nextWrapper {
  flex-basis: 50%;
  margin-left: auto;
  text-align: right;
}

.prevWrapper,
.nextWrapper {
  a {
    display: inline-block;
    margin: 0 var(--spacing-03);
    color: var(--gray-dark);
  }
}

.firstItemLink,
.lastItemLink {
  svg {
    margin: 0 -6px;
  }
}

.itemWrapper {
  display: flex;
  gap: var(--spacing-03) 0;
  flex-basis: 100%;
  flex-wrap: wrap;
  margin: var(--spacing-03) 0 0;
  padding: 0;
  list-style-type: none;
}

.item {
  flex: 1 0 20%;
  padding: 0 var(--spacing-03);
  text-align: center;

  &:not(.active) {
    color: var(--gray-dark);

    &:hover span {
      text-decoration: underline;
    }
  }

  @include mixins.devices(tablet) {
    flex: 1;
  }

  a {
    display: inline-block;
    width: 100%;
  }
}

.subPagination {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style-type: none;

  a {
    padding: 0 var(--spacing-02);
    color: var(--gray-dark);

    &:hover {
      text-decoration: underline;
    }
  }
}

@include mixins.devices(desktop) {
  .root {
    justify-content: center;
  }

  .itemWrapper,
  .prevWrapper,
  .nextWrapper {
    flex-basis: unset;
    margin: unset;
  }

  .itemWrapper {
    order: 2;
  }

  .prevWrapper {
    order: 1;
    padding-top: var(--spacing-01);
  }

  .nextWrapper {
    order: 3;
    padding-top: var(--spacing-01);
  }
}
